import * as React from "react";
import styled from "styled-components";
import { theme } from "../../components/theme";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import { Meta } from "../../components/Meta";
import { Button, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import Logger from "../../util/Logger";
import { ProgressContext } from "../../context/progress";

interface IGPXPageProps {}

const Gpx: React.FC<IGPXPageProps> = ({}) => {
  const [mapUrl, setMapUrl] = useState<string>("");

  const { setIsLoading } = useContext(ProgressContext);

  const handlerOnClick = () => {
    setIsLoading(true);

    const exportGPX = firebase.functions().httpsCallable("exportGPX");

    let blobUrl: string;
    exportGPX({ url: mapUrl })
      .then((res) => {
        const blob = new Blob([res.data.gpxXML], { type: "application/xml" });
        blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.download = `DirectionRoute.gpx`;
        link.href = blobUrl;
        link.click();
        setMapUrl("");
      })
      .catch((e) => {
        Logger.debug(e);
        alert(e.message);
      })
      .finally(() => {
        window.URL.revokeObjectURL(blobUrl);
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>kame's blog - GPX</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Container>
        <h1 style={{ width: "100%", textAlign: "center" }}>GPX Generator</h1>

        <Body>
          <p>
            <a
              href={"https://www.google.co.jp/maps/?hl=ja"}
              target={"_blank"}
              rel="noreferrer"
            >
              GoogleMap
            </a>
            で作成したルートからGPXファイルを生成
          </p>

          <form noValidate autoComplete="off">
            <TextField
              id="map-url"
              label="map-url"
              variant="outlined"
              fullWidth
              value={mapUrl}
              onChange={(event) => setMapUrl(event.target.value)}
            />
          </form>

          <Button
            variant="contained"
            onClick={handlerOnClick}
            fullWidth
            style={{ marginTop: "16px" }}
          >
            Submit
          </Button>
        </Body>
      </Container>
    </Layout>
  );
};

export default Gpx;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 12px;

  width: 100vw;
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.body};
    padding: 0;
  }
`;

const Body = styled.div`
  color: #000;
`;
